import React, { useEffect } from "react";
import Chart from "react-apexcharts";

const CustomCharts = ({ chartState, setChartState }) => {
    useEffect(() => {
        const series = chartState.series;
        let dataChanged = false;
        series.forEach((serie) => {
            if (typeof serie.name !== 'string') {
                if (typeof serie.name === 'object') {
                    if ('Province' in serie.name) {
                        serie.name = serie.name.Province;
                    } else {
                        serie.name = Object.keys(serie.name).reduce((a, b) => {
                            return a + b + ' ';
                        }, '');
                    }
                    dataChanged = true;
                }
            }
        });
        if (dataChanged) {
            setChartState({ ...chartState, series });
        }
    }, [chartState]);

    return (
        <>
            <div id="chart-line2">
                <Chart options={chartState.options} series={chartState.series} type="line" height={430} />
            </div>
            <div id="chart-line">
                <Chart options={chartState.optionsLine} series={chartState.seriesLine} type="area" height={130} />
            </div>
        </>
    )
};

export default CustomCharts;
