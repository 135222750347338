import React, { useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import "./css/Footer.css";
// import googlePlay from "../assets/images/google-play.png";
// import appStore from "../assets/images/app-store.png";

const Footer = () => {
  const [termsModal, setTermsModal] = useState(false);
  const [privacyModal, setPrivacyModal] = useState(false);

  return (
    <footer className="bg-light py-5">
      <Container className="footer-container">
        <Row>
          <Col md={6}>
            <div className="mb-4">
              <img src="logo.jpeg" alt="Logo" width="48" />
            </div>
            <p className="text-muted">
              Our data comes from the National Oceanic and Atmospheric Administration (NOAA), a reliable source for weather, climate, and oceanographic information. NOAA collects and provides high-quality environmental data from around the world. This ensures our information is accurate and up-to-date.
            </p>
          </Col>
          <Col md={6} style={{ marginTop: "2rem" }}>
            <h4>About Us</h4>
            <p className="text-muted">
              Potomac,
              548 One City Center, 38th Floor,
              Ploenchit, Lumpini, Pathumwan,
              Bangkok, Thailand 10330
            </p>
            <p className="text-muted">
              Email:{" "}
              <a href="mailto:contact@potomachq.co">contact@potomachq.co</a>
            </p>
            <div className="d-flex flex-column">
              <span
                className="footer-link"
                onClick={() => setTermsModal(true)}
                role="button"
              >
                Terms and Conditions
              </span>
              <span
                className="footer-link"
                onClick={() => setPrivacyModal(true)}
                role="button"
              >
                Privacy Policy
              </span>
            </div>
            <div className="text-end mt-4 text-muted">
              © 2025 by Potomac. All rights reserved.
            </div>
          </Col>
        </Row>
      </Container>

      {/* Terms Modal */}
      <Modal show={termsModal} onHide={() => setTermsModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body className="footer-modal" style={{ maxHeight: "80vh", overflowY: "auto" }}>
          <p><strong>§ 1 General Provisions</strong></p>
          <p>
            (1) Potomac, headquartered in Bangkok, operates the platform “potomachq.co”
            (referred to as “Potomac”, “the Platform”, “the Potomac Platform”). The Platform enables users to
            interact with blockchain-based parametric smart contracts on the Ethereum network. These smart
            contracts are designed to help users assess climate risks, reduce potential exposure, and enhance
            resilience against disasters. Potomac provides tools, resources, and access but does not guarantee
            outcomes or offer direct risk-mitigation services.
          </p>

          <p><strong>§ 2 Potomac's Platform and Services</strong></p>
          <p>
            (1) Potomac offers a platform for users to access and utilize parametric smart contracts on the
            Ethereum blockchain. These contracts enable users to address climate risks by automating payouts
            based on predefined parameters.
          </p>
          (2) Potomac's services include:
          <ol type="a">
            <li>Providing access to the platform (“potomachq.co”);</li>
            <li>Assisting users with the account setup process;</li>
            <li>Offering tools and resources to help users identify and assess climate risks;</li>
            <li>Providing general information and support related to the use of the platform and parametric
              smart contracts.</li>
          </ol>
          <p>
            (3) Potomac acts solely as a technology provider and does not offer financial or insurance advice.
            Users are responsible for understanding the terms, conditions, and risks associated with any smart
            contract they create or interact with on the platform. Potomac makes no representations or
            warranties regarding the suitability, legality, or effectiveness of any smart contract deployed or
            accessed through its platform.
          </p>
          <p>
            (4) Potomac may provide additional resources, such as educational materials or risk assessment
            tools, to help users make informed decisions about their use of parametric smart contracts.
          </p>

          <p><strong>§ 3 User Responsibilities</strong></p>
          <p>
            (1) Wallet Security: Users are solely responsible for the security of their cryptocurrency wallets
            and private keys used to access and interact with smart contracts on the Potomac platform. This
            includes implementing adequate measures to prevent unauthorized access, loss, or theft.
          </p>
          <p>
            (2) Compliance and Acceptable Use: Users are solely responsible for understanding and complying with
            all applicable laws and regulations related to their use of the platform and any smart contracts
            they create or interact with. The platform must not be used for illegal or unauthorized purposes.
          </p>

          <p><strong>§ 4 Confidentiality</strong></p>
          <p>
            Users agree to maintain the confidentiality of Potomac’s proprietary information, including
            technology, security practices, and business operations. Disclosure to third parties is prohibited
            without prior written consent from Potomac, except as required by law.
          </p>

          <p><strong>§ 5 Termination</strong></p>
          (1) Potomac reserves the right to suspend or terminate a user's access to the platform for reasons
          including, but not limited to:

          <ol type="a">
            <li>Violation of these Terms and Conditions;</li>
            <li>Use of the platform for illegal or unauthorized purposes;</li>
            <li>Failure to comply with applicable laws and regulations;</li>
            <li>Breach of Potomac's security measures;</li>
            <li>Any action that may harm Potomac, its users, or the Ethereum network.</li>
          </ol>
          <p>
            (2) Potomac will make reasonable efforts to notify users before suspending or terminating access,
            except where immediate action is necessary to protect the platform or its users.
          </p>

          <p><strong>§ 6 Limitation of Liability</strong></p>
          <p>
            (1) Potomac provides the platform and its services "as is" without any warranties, express or
            implied, including but not limited to warranties of merchantability, fitness for a particular
            purpose, or non-infringement.
          </p>
          <p>
            (2) Potomac will not be liable for any indirect, incidental, special, consequential, or punitive
            damages arising out of or related to the use of the platform or any smart contracts accessed through
            the platform. This includes, but is not limited to, lost profits, data loss, or damages resulting
            from network disruptions or security breaches.
          </p>
          <p>
            (3) To the maximum extent permitted by law, Potomac's total liability to any user for any claim
            arising out of or relating to these Terms and Conditions shall be limited to the amount of fees paid
            by that user to Potomac in the twelve (12) months preceding the event giving rise to the claim.
          </p>
          <p>
            (4) These limitations of liability also apply to Potomac's officers, directors, employees, and
            agents.
          </p>
          <p>
            (5) Potomac is not responsible for the content, accuracy, or availability of any third-party
            websites or resources linked to or from the platform.
          </p>
          <p>
            (6) These limitations of liability apply to the fullest extent permitted by law and will survive any
            termination of these Terms and Conditions.
          </p>

          <p><strong>§ 7 Third-Party Links and Indemnification</strong></p>
          <p>
            (1) Third-Party Links: Potomac may provide links to third-party websites or resources for user
            convenience. Potomac does not endorse or control the content of these third-party resources and is
            not responsible for their accuracy, availability, or any damages that may result from their use.
          </p>
          <p>
            (2) Indemnification: Users agree to indemnify and hold harmless Potomac, its affiliates, officers,
            directors, employees, and agents from any and all claims, losses, damages, liabilities, costs, and
            expenses arising out of or related to their use of the platform or their violation of these Terms
            and Conditions.
          </p>

          <p><strong>§ 8 Data Protection</strong></p>
          <p>
            (1) Data Security: Potomac collects and processes user data in compliance with applicable data
            protection laws, including the Thailand Personal Data Protection Act B.E. 2562 (2019) (“PDPA”).
          </p>
          <p>
            (2) User Consent: By using the Potomac platform, users consent to the collection, processing, and
            storage of their anonymized data for purposes including platform improvement and marketing. Potomac
            does not sell personal data to third parties without user consent.
          </p>

          <p><strong>§ 9 Governing Law</strong></p>
          <p>
            These Terms and Conditions are governed by and construed in accordance with the laws of Thailand,
            and any disputes arising under or in connection with them shall be resolved in the courts of
            Thailand.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setTermsModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Privacy Modal */}
      <Modal show={privacyModal} onHide={() => setPrivacyModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Privacy Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body className="footer-modal" style={{ maxHeight: "80vh", overflowY: "auto" }}>

          <section>
            <p><strong>1. Data Collection</strong></p>
            <ul>
              <li>Personal Information: We collect personal details, such as name, address, and proof of
                residency, for residency verification.</li>
              <li>Climate-Related Activity Data: This data is gathered to calculate rewards and payouts.</li>
              <li>Payment Information: Collected to facilitate the disbursement of rewards and payouts.</li>
            </ul>
          </section>

          <section>
            <p><strong>2. Data Usage</strong></p>
            <ul>
              <li>Personal information is used to verify eligibility and manage program contracts.</li>
              <li>NOAA climate data is utilized to determine program rewards and payouts.</li>
              <li>Aggregate and anonymized data may be used for program improvement and reporting purposes.
              </li>
            </ul>
          </section>

          <section>
            <p><strong>3. Data Sharing</strong></p>
            Potomac does not sell or share personal data with third parties, except as necessary for:
            <ol type="a">
              <li>Program administration and compliance with applicable laws.</li>
              <li>Payment processing through secure third-party services.</li>
            </ol>
          </section>
          <section>
            <p><strong>4. Data Security</strong></p>
            <p>
              Potomac employs industry-standard security measures to safeguard user data. While we strive to
              protect your information, no system can guarantee absolute security.
            </p>
          </section>

          <section>
            <p><strong>5. User Rights</strong></p>
            Users have the right to:
            <ol type="a">
              <li>Request access to their data.</li>
              <li>Request corrections for inaccuracies.</li>
              <li> Request deletion of their personal information, subject to legal and program
                constraints. Requests can be submitted through Potomac’s designated support channels.</li>
            </ol>
          </section>
          <section>
            <p><strong>6. Retention of Data</strong></p>
            <p>
              Personal data is retained only as long as necessary to fulfill program purposes or comply with
              legal obligations.
            </p>
          </section>

          <section>
            <p><strong>7. Changes to Privacy Policy</strong></p>
            <p>
              Potomac reserves the right to update this Privacy Policy to reflect changes in practices or
              legal requirements. Users will be notified of significant updates.
            </p>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setPrivacyModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </footer>
  );
};

export default Footer;
