export function transformDataTable(data, predictData) {
  const transformedData = {
    heatidx: [], temp: [], temp2m: [], relhum: [], precip: [], tpw: [], pm25: [], accum_rain_rate: []
  };

  const calculateStats = (values) => {
    values.sort((a, b) => a - b);
    const sum = values.reduce((acc, curr) => acc + curr, 0);
    return {
      min: values[0],
      max: values[values.length - 1],
      mean: sum / values.length,
      median: values.length % 2 === 0 ? (values[values.length / 2 - 1] + values[values.length / 2]) / 2 : values[Math.floor(values.length / 2)]
    };
  };

  const last52WeeksDate = new Date();
  last52WeeksDate.setDate(last52WeeksDate.getDate() - 365);

  const minMaxLast52Weeks = Object.fromEntries(Object.keys(transformedData).map(prop => [prop, [Infinity, -Infinity]]));

  data.features.forEach(({ properties }) => {
    const { time, precip } = properties;
    const [date, hour] = time.split('T');
    const hourKey = `T${parseInt(hour.split(':')[0])}`;

    Object.keys(transformedData).forEach(prop => {
      if (properties[prop] != null) {
        const propData = transformedData[prop];
        let entry = propData.find(item => item.date === date);

        if (!entry) {
          entry = { date, temps: { [prop]: [properties[prop]] } };
          propData.push(entry);
        } else {
          entry.temps[prop].push(properties[prop]);
        }
        entry[hourKey] = properties[prop];
      }
    });
  });

  const statsCollections = ['min', 'max', 'mean', 'median', "accum_rain_rate", "rain_rate"].reduce((acc, stat) => {
    acc[stat] = Object.fromEntries(Object.keys(transformedData).map(prop => [prop, []]));
    return acc;
  }, {});

  transformedData.precip.forEach(item => {
    if (item.sum != null) {
      item.sum = parseFloat(item.sum.toFixed(1));
      item.accum_rain_rate = parseFloat(item.accum_rain_rate.toFixed(1));
    }
  });

  Object.keys(transformedData).forEach(property => {
    if (property === 'precip') {
      transformedData[property].forEach(item => {
        const sum = item.temps.precip.reduce((acc, curr) => acc + curr, 0).toFixed(1);
        statsCollections['rain_rate'][property].push({ x: new Date(item.date), y: sum })
        if (new Date(item.date) > last52WeeksDate) {
          minMaxLast52Weeks[property][0] = Math.min(minMaxLast52Weeks[property][0], sum);
          minMaxLast52Weeks[property][1] = Math.max(minMaxLast52Weeks[property][1], sum);
        }
        item.sum = parseFloat(sum);
      });
    } else if (property === 'accum_rain_rate') {
      transformedData[property].forEach(item => {
        const max = Math.max(...item.temps.accum_rain_rate).toFixed(1);
        statsCollections['accum_rain_rate'][property].push({ x: new Date(item.date), y: max });
        item.accum_rain_rate = max;
      })

    } else {
      transformedData[property].forEach(item => {
        const stats = calculateStats(item.temps[property] || []);
        Object.keys(stats).forEach(stat => {
          statsCollections[stat][property].push({ x: new Date(item.date), y: stats[stat].toFixed(1) });
          item[stat] = stats[stat].toFixed(1);
        });
        if (new Date(item.date) > last52WeeksDate) {
          minMaxLast52Weeks[property][0] = Math.min(minMaxLast52Weeks[property][0], stats['max']);
          minMaxLast52Weeks[property][1] = Math.max(minMaxLast52Weeks[property][1], stats['max']);
        }
      });
    }
  });
  let statsCollectionsAndPredict = structuredClone(statsCollections);

  if (predictData) {
    predictData.features.forEach(({ properties }) => {
      const { time } = properties;
      const date = new Date(time.split('T')[0]);
      Object.keys(transformedData).forEach(prop => {
        if (properties[prop] != null) {
          Object.keys(statsCollectionsAndPredict).forEach(stat => {
            statsCollectionsAndPredict[stat][prop].push({ x: date, y: properties[prop] });
          });
        }
      });
    });
  }
  const lastestData = data.features[data.features.length - 1].properties;

  let summaryData = {
    "heatidx": lastestData.heatidx,
    "temp": lastestData.temp,
    "temp2m": lastestData.temp2m,
    "relhum": lastestData.relhum,
    "precip": transformedData["precip"][transformedData["precip"].length - 1].sum,
    "accum_rain_rate": lastestData.accum_rain_rate,
    "tpw": lastestData.tpw,
    "pm25": lastestData.pm25,
    "heatidx52Range": minMaxLast52Weeks["heatidx"].join(' - '),
    "temp52Range": minMaxLast52Weeks["temp"].join(' - '),
    "temp2m52Range": minMaxLast52Weeks["temp2m"].join(' - '),
    "relhum52Range": minMaxLast52Weeks["relhum"].join(' - '),
    "tpw52Range": minMaxLast52Weeks["tpw"].join(' - '),
    "precip52Range": minMaxLast52Weeks["precip"].join(' - '),
    "pm2552Range": minMaxLast52Weeks["pm25"].join(' - ')
  }

  return {
    no_predict_data: statsCollections,
    ...statsCollectionsAndPredict,
    table_data: transformedData,
    summaryData
  };
}