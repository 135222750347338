import React, { useEffect } from 'react';
import {
    DropdownButton,
    ButtonGroup,
    Dropdown,
    Button,
    Form,
    InputGroup,
    ToggleButton,
} from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { transformDataTable } from "./TransformedData";
import CustomCharts from "./charts/CustomCharts";
import GetPredictData from '../components/GetPredictData';
import Services from "../services";
import Constants from '../constants';
import getSerieType from '../utils/getSerieType';

const AnalyticsView = ({
    bigLineChartOptions,
    chartState,
    setChartState,
    heatIndexAnnotation,
    predictLenght,
    xaxisline,
    selectLocationType,
    transformData,
    setTransformData,
    latitude,
    longitude,
    handleTypeChange,
    selectedCountry,
    citylist,
    authToken,
    yearOptions,
    months,
    selectionRadios,
    redirectIfIsNotSubscribeUser,
    handleIndicatiorChange,
    handleCompareTypeChange,
    locationRadios,
    setModalShow,
    chartLoading,
    setChartLoading,
    selectType,
    compareTypeOption,
    selectedIndicatior,
    isSubscribeUser,
    selectedCompareTypeOptions,
    setSelectedCompareTypeOptions,
    compareLocationList,
    setCompareLocationList,
    chartStartDate,
    setChartStartDate,
    chartEndDate,
    setChartEndDate,
}) => {
    const [indicatorOption] = React.useState([
        { value: 'max', label: 'Maximum' },
        { value: 'min', label: 'Minimum' },
        { value: 'mean', label: 'Mean' },
        { value: 'median', label: 'Median' }
    ]);
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const [isCompareDropdownOpen, setIsCompareDropdownOpen] = React.useState(false);
    const [indicatiorRadioValue, setIndicatiorRadioValue] = React.useState('variables');
    const [selectCompareLocationType, setSelectCompareLocationType] = React.useState('Location');
    const [compareLatitude, setCompareLatitude] = React.useState('');
    const [compareLongitude, setCompareLongitude] = React.useState('');
    const [selectedCompareCountry, setSelectedCompareCountry] = React.useState(null);
    const [yearList, setYearList] = React.useState([]);
    const [selectionChart, setSelectionChart] = React.useState('Daily');

    const indicatiorRadios = [
        { name: "Variables", value: "variables" },
        { name: "Locations", value: "locations" },
        { name: "Year Over Year", value: "history" },
    ];

    const handleCompareLatitudeChange = (event) => {
        setCompareLatitude(event.target.value);
    };

    const handleCompareLongitudeChange = (event) => {
        setCompareLongitude(event.target.value);
    };

    const handleCompareCountryChange = (selectedOption) => {
        setSelectedCompareCountry(selectedOption);
        setCompareLatitude(selectedOption.value.Latitude);
        setCompareLongitude(selectedOption.value.Longitude);
    };

    const handleAddCompareLocation = async () => {
        if (!compareLatitude || !compareLongitude) {
            alert('Please enter latitude and longitude');
            return;
        }
        let location = {};
        if (selectCompareLocationType === 'Location') {
            location = { type: 'Location', lat: compareLatitude, lon: compareLongitude }
        } else {
            location = { type: 'Country', location: selectedCompareCountry.value.Province, lat: selectedCompareCountry.value.Latitude, lon: selectedCompareCountry.value.Longitude }
        }
        const foundIndex = compareLocationList.findIndex(item => (
            item.lat === location.lat && item.lon === location.lon
        ));

        if (foundIndex === -1 && latitude !== location.lat && longitude !== location.lon) {
            setIsCompareDropdownOpen(false);
            setSelectedCompareTypeOptions([]);
            setChartLoading(true);
            const key = `${location.lat}_${location.lon}`;
            if (key in transformData) {
                const data = transformData[key];
                updateChartWithData(data, location);
            } else {
                await fetchAndTransformData(location, key);
            }
            const newCompareLocationList = [...compareLocationList, location];
            setCompareLocationList(newCompareLocationList);
        } else {
            alert('Location already exists in the list');
        }
    };

    const updateChartWithData = (data, location) => {
        let bigLineChartOptionsTemp = { ...bigLineChartOptions };
        bigLineChartOptionsTemp.yaxis = [{ title: { text: selectType.unit } }];
        bigLineChartOptionsTemp.colors = [bigLineChartOptionsTemp.colors[0], getRandomColor()];
        if (selectType.value === "heatidx") {
            bigLineChartOptionsTemp.annotations = heatIndexAnnotation;
        } else {
            bigLineChartOptionsTemp.annotations.yaxis = []
        }
        if (isSubscribeUser) {
            if (selectType.value !== "precip" && selectType.value !== "accum_rain_rate") {
                bigLineChartOptionsTemp.forecastDataPoints = { count: predictLenght }
                bigLineChartOptionsTemp.annotations.xaxis = [{
                    x: xaxisline,
                    strokeDashArray: 0,
                    borderColor: '#775DD0'
                }];
            }
        }

        let firstData = chartState.series[0];
        let minTemp = chartState.optionsLine.chart.selection.xaxis.min;
        let maxTemp = chartState.optionsLine.chart.selection.xaxis.max;
        if (typeof firstData.name === 'number') {
            firstData.data = transformData[`${latitude}_${longitude}`][selectedIndicatior.value][selectType.value];
            minTemp = chartStartDate.getTime();
            maxTemp = chartEndDate.getTime();
        }
        firstData.name = selectLocationType === 'Country' ? selectedCountry.value : `(${latitude},${longitude})`;
        let newData;
        if (selectedIndicatior.value === 'min_max') {
            newData = { name: location.type === 'Country' ? location.location : `(${location.lat},${location.lon})`, type: "line", data: data['max'][selectType.value] };
        } else {
            newData = { name: location.type === 'Country' ? location.location : `(${location.lat},${location.lon})`, type: "line", data: data[selectedIndicatior.value][selectType.value] };
        }
        const currentSerieType = chartState.series.length > 0 ? getSerieType(chartState.series[0]) : undefined;
        const newSeries = [...chartState.series, newData].filter((serie) => {
            return currentSerieType ? getSerieType(serie) === currentSerieType : true;
        });
        setChartState(prevState => ({
            ...prevState, series: newSeries, options: bigLineChartOptionsTemp, seriesLine: [firstData],
            optionsLine: {
                ...prevState.optionsLine, chart: { ...prevState.optionsLine.chart, selection: { enabled: true, xaxis: { min: minTemp, max: maxTemp } } },
            }
        }));
        setChartLoading(false);
    };

    const fetchAndTransformData = async (location, key) => {
        let historyData, predictData;
        if (isSubscribeUser) {
            [historyData, predictData] = await Promise.all([
                Services.HistoryData.getHistoryData(location.lat, location.lon, authToken),
                GetPredictData(location.lat, location.lon, authToken)
            ]);
        } else {
            [historyData] = await Promise.all([
                Services.HistoryData.getHistoryData(latitude, longitude, authToken),
            ]);
            predictData = { features: [] };
        }

        const data = transformDataTable(historyData, predictData);
        setTransformData({ ...transformData, [key]: data });
        updateChartWithData(data, location);
    };

    const handleYearChange = (option) => {
        if (yearList.includes(option)) {
            setYearList(yearList.filter(year => year !== option))
        } else {
            setYearList([...yearList, option])
        }
    };

    const handleHistoryCompare = () => {
        if (yearList.length === 0) {
            alert('Please select compare year');
        } else {
            setIsCompareDropdownOpen(false);
            let tempSeriesData = [];
            let tempData = [...transformData[`${latitude}_${longitude}`].no_predict_data[selectedIndicatior.value][selectType.value]];
            let chartStateYAxisTemp = chartState.options.yaxis;
            let chartStateColorTemp = chartState.options.colors;
            chartStateColorTemp = [chartStateColorTemp[0]];
            chartStateYAxisTemp = [chartStateYAxisTemp[0]];
            for (let year of yearList) {
                chartStateColorTemp.push(getRandomColor());
                const dataInYear = tempData.filter(item => {
                    return item.x.getFullYear() === year;
                });

                const seriesDataForYear = {
                    name: year,
                    data: dataInYear.map(item => [new Date(2024, item.x.getMonth(), item.x.getDate()).getTime(), item.y])
                };
                tempSeriesData.push(seriesDataForYear);
            }
            setChartState(prevState => ({
                ...prevState,
                series: tempSeriesData,
                seriesLine: [tempSeriesData[0]],
                options: {
                    ...prevState.options,
                    yaxis: chartStateYAxisTemp,
                    colors: chartStateColorTemp,
                    forecastDataPoints: { count: 0 },
                    annotations: { ...prevState.options.annotations, xaxis: [] }
                },
                optionsLine: { ...prevState.optionsLine, chart: { ...prevState.optionsLine.chart, selection: { ...prevState.optionsLine.chart.selection, xaxis: { min: new Date('2024-01-01').getTime(), max: new Date('2024-12-31').getTime() } } } }
            }));
            setCompareLocationList([]);
            setSelectedCompareTypeOptions([]);
        }
    };

    const updateChartData = (timeline) => {
        setSelectionChart(timeline);
        let currentDate = new Date();
        setChartEndDate(new Date());
        switch (timeline) {
            case '1M':
                currentDate.setMonth(currentDate.getMonth() - 1);
                setChartStartDate(currentDate);
                break;
            case '3M':
                currentDate.setMonth(currentDate.getMonth() - 3);
                setChartStartDate(currentDate);
                break;
            case '6M':
                currentDate.setMonth(currentDate.getMonth() - 6);
                setChartStartDate(currentDate);
                break;
            case '1Y':
                currentDate.setFullYear(currentDate.getFullYear() - 1);
                setChartStartDate(currentDate);
                break;
            case '3Y':
                currentDate.setFullYear(currentDate.getFullYear() - 3);
                setChartStartDate(currentDate);
                break;
            case '5Y':
                currentDate.setFullYear(currentDate.getFullYear() - 5);
                setChartStartDate(currentDate);
                break;
            case 'YTD':
                currentDate.setMonth(0);
                currentDate.setDate(1);
                setChartStartDate(currentDate);
                break;
            case 'All':
                currentDate = transformData[`${latitude}_${longitude}`][selectedIndicatior.value][selectType.value][0].x;
                setChartStartDate(currentDate);
                break;
            default:
                break;
        }
        setChartState(prevState => ({
            ...prevState,
            optionsLine: {
                ...prevState.optionsLine,
                chart: {
                    ...prevState.optionsLine.chart,
                    selection: {
                        ...prevState.optionsLine.chart.selection,
                        xaxis: { min: currentDate.getTime(), max: new Date().getTime() }
                    }
                }
            }
        }));
    };

    const handleChartDateRangeSearch = () => {
        setIsDropdownOpen(false);
        setChartState(prevState => ({
            ...prevState,
            optionsLine: {
                ...prevState.optionsLine,
                chart: {
                    ...prevState.optionsLine.chart,
                    selection: {
                        ...prevState.optionsLine.chart.selection,
                        xaxis: { min: chartStartDate.getTime(), max: chartEndDate.getTime() }
                    }
                }
            }
        }));
    };

    const handleCancelClick = () => {
        setIsDropdownOpen(false);
    };

    const handleReset = () => {
        const mapData = transformData[`${latitude}_${longitude}`][selectedIndicatior.value][selectType.value];
        let data = [{ name: selectType.value, type: "line", data: mapData }];
        let bigLineChartOptionsTemp = { ...bigLineChartOptions }
        bigLineChartOptionsTemp.yaxis = [{ title: { text: selectType.unit }, seriesName: [selectType.label] }];
        bigLineChartOptionsTemp.colors = [selectType.color];
        if (selectType.value === "heatidx") {
            bigLineChartOptionsTemp.annotations = heatIndexAnnotation;
        } else {
            bigLineChartOptionsTemp.annotations = { yaxis: [], xaxis: [] }
        }
        if (isSubscribeUser) {
            if (selectType.value !== "precip" && selectType.value !== "accum_rain_rate") {
                bigLineChartOptionsTemp.forecastDataPoints = { count: predictLenght }
                bigLineChartOptionsTemp.annotations.xaxis = [{
                    x: xaxisline,
                    strokeDashArray: 0,
                    borderColor: '#775DD0'
                }];
            }
        }

        setCompareLocationList([]);
        setSelectedCompareTypeOptions([]);
        setChartState(prevState => ({
            ...prevState,
            series: data,
            options: bigLineChartOptionsTemp,
            seriesLine: data,
            optionsLine: {
                ...prevState.optionsLine,
                chart: {
                    ...prevState.optionsLine.chart,
                    selection: {
                        ...prevState.optionsLine.chart.selection,
                        xaxis: { min: chartStartDate.getTime(), max: chartEndDate.getTime() }
                    }
                }
            }
        }));
    };

    const handleDeleteLocation = (index) => {
        let tempLocationList = [...compareLocationList];
        tempLocationList.splice(index, 1);
        let tempSeries = [...chartState.series];
        tempSeries.splice(index + 1, 1);
        let tempColor = [...chartState.options.colors];
        tempColor.splice(index + 1, 1);
        setCompareLocationList(tempLocationList);
        setChartState(prevState => ({
            ...prevState,
            series: tempSeries,
            options: {
                ...prevState.options,
                colors: tempColor
            },
            optionsLine: {
                ...prevState.optionsLine,
                chart: {
                    ...prevState.optionsLine.chart,
                    selection: {
                        ...prevState.optionsLine.chart.selection,
                        xaxis: { min: chartStartDate.getTime(), max: chartEndDate.getTime() }
                    }
                }
            }
        }));
    };

    function getRandomColor() {
        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div
            className={`${selectionChart === 'dateRange' ? 'date-range-dropdown date-range-active' : 'date-range-dropdown'}`}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            <i
                className={`${selectionChart === 'dateRange' ? 'fa fa-calendar date-range-active' : 'fa fa-calendar'}`}
                aria-hidden='true'
                style={{ marginRight: '5px', fontSize: '14px' }}
            />
            <div
                className={`${selectionChart === 'dateRange' ? 'date-range-title date-range-active' : 'date-range-title'}`}
            >
                Date Range
            </div>
        </div>
    ));

    return (
        <div className="content-tab">
            <div style={{ margin: '10px 0px 0px 0px', display: "flex", justifyContent: "space-between" }}>
                <div>
                    <DropdownButton
                        className="dropdown-type-chart-type"
                        variant="white"
                        size="md"
                        as={ButtonGroup}
                        title={`Type: ${selectType.label}`}
                        id="input-group-dropdown-3"
                    >
                        {Constants.typeOptions.map(option => (
                            <Dropdown.Item key={option.value} href="#" onClick={() => handleTypeChange(option)}>
                                {option.label}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                    <div style={{ display: 'inline', marginRight: '10px' }}></div>

                    <DropdownButton
                        className="dropdown-type-chart"
                        variant="white"
                        size="sm"
                        as={ButtonGroup}
                        title={`Indicators: ${selectedIndicatior.label}`}
                        id="input-group-dropdown-3"
                    >
                        {indicatorOption.map(option => (
                            <Dropdown.Item key={option.value} href="#" onClick={() => handleIndicatiorChange(option)}>
                                {option.label}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                    <div style={{ display: 'inline', marginRight: '10px' }}></div>
                    <DropdownButton
                        id="dropdown-checkbox"
                        className="dropdown-type-chart"
                        variant="white"
                        size="sm"
                        as={ButtonGroup}
                        show={isCompareDropdownOpen}
                        onToggle={(isOpen) => setIsCompareDropdownOpen(isOpen)}
                        style={isSubscribeUser ? {} : { filter: "blur(2px)" }}
                        onClick={() => redirectIfIsNotSubscribeUser()}
                        title={
                            <>
                                Comparison
                                {/* <Badge bg="secondary">{selectedCompareTypeOptions.length}</Badge> */}
                            </>
                        }
                    >
                        <Dropdown.Header>
                            <ButtonGroup style={{ width: '100%' }}>
                                {indicatiorRadios.map((radio, idx) => (
                                    <ToggleButton
                                        key={idx}
                                        id={`radio-${idx}`}
                                        className="compare-radio-button"
                                        type="radio"
                                        variant="outline-secondary"
                                        name="radio"
                                        value={indicatiorRadioValue.value}
                                        checked={indicatiorRadioValue === radio.value}
                                        onChange={(e) => setIndicatiorRadioValue(radio.value)}
                                    >
                                        {radio.name}
                                    </ToggleButton>
                                ))}
                            </ButtonGroup>
                        </Dropdown.Header>
                        {indicatiorRadioValue === 'variables' ? compareTypeOption.map(option => (
                            <Dropdown.Item key={option.value} eventKey={option.value} onClick={() => handleCompareTypeChange(option)}>
                                <Form.Check
                                    type="checkbox"
                                    label={option.label}
                                    checked={selectedCompareTypeOptions.includes(option)}
                                    readOnly
                                />
                            </Dropdown.Item>
                        )) : (indicatiorRadioValue === 'locations' ?
                            <Dropdown.Header>
                                <ButtonGroup style={{ width: '100%' }}>

                                    {locationRadios.map((radio, idx) => (
                                        <ToggleButton
                                            key={idx}
                                            type="radio"
                                            variant="outline-secondary"
                                            value={radio.value}
                                            checked={selectCompareLocationType === radio.value}
                                            onClick={(e) => setSelectCompareLocationType(radio.value)}
                                            style={selectCompareLocationType === radio.value ? { borderColor: '#828A92', color: 'white', backgroundColor: '#828A92' } : { borderColor: '#828A92', color: '#828A92', backgroundColor: 'transparent' }} // Adjust styles here
                                        >
                                            {radio.name}
                                        </ToggleButton>
                                    ))}
                                </ButtonGroup>
                                <InputGroup style={{ width: '100%', marginTop: '10px' }}>
                                    {selectCompareLocationType === 'Location' ? (
                                        <>
                                            <InputGroup.Text>Lat:</InputGroup.Text>
                                            <Form.Control
                                                value={compareLatitude}
                                                onChange={handleCompareLatitudeChange}
                                                aria-label="Latitude"
                                            />
                                            <InputGroup.Text>Lon:</InputGroup.Text>
                                            <Form.Control
                                                value={compareLongitude}
                                                onChange={handleCompareLongitudeChange}
                                                aria-label="Longitude"
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Select
                                                className="basic-single flex-grow-1" // Use flex-grow-1 class to make Select fill remaining space
                                                classNamePrefix="select"
                                                value={selectedCompareCountry}
                                                onChange={handleCompareCountryChange}
                                                options={citylist}
                                                width={200}
                                                isSearchable // Add this if you want a searchable Select
                                            />
                                        </>
                                    )}

                                </InputGroup >
                                {compareLocationList.map((location, index) => (<div key={index} className="location-cancel-div"><i className="fa fa-times location-cancel-button" aria-hidden="true" onClick={() => handleDeleteLocation(index)} /> {location.type === "Location" ? <>lat-lon:({location.lat},{location.lon})</> : <>Location:{location.location}({location.lat},{location.lon})</>}</div>))}
                                <Button style={{ width: '100%', marginTop: '10px' }} variant="secondary" onClick={() => handleAddCompareLocation()}>Add</Button>
                            </Dropdown.Header>
                            :
                            <Dropdown.Header>

                                <div className="compare-div-mini-yoy">
                                    {/*   <ButtonGroup style={{ width: '100%' }}> */}
                                    {yearOptions.map((radio, idx) => (
                                        <Button
                                            key={idx}
                                            type="radio"
                                            variant="outline-secondary"
                                            className="compare-radio-button-yoy"
                                            name="radio"
                                            value={radio}
                                            onClick={() => handleYearChange(radio)}
                                            style={yearList.includes(radio) ? { borderColor: '#828A92', color: 'white', backgroundColor: '#828A92' } : { borderColor: '#828A92', color: '#828A92', backgroundColor: 'transparent' }} // Adjust styles here
                                        >
                                            {radio}
                                        </Button>
                                    ))}
                                    {/* </ButtonGroup> */}
                                </div>
                                <Button className="compare-button-yoy" variant="secondary" onClick={() => handleHistoryCompare()}>Compare</Button>
                            </Dropdown.Header>)}
                    </DropdownButton>
                    <div className="btn-group reset-button-home" onClick={() => handleReset()}>Reset</div>
                </div>
                {selectType.label === 'Heat Index' ? <i className="fa fa-info-circle info-button" aria-hidden="true" onClick={() => setModalShow(true)} /> : <></>}
            </div>
            <div className="row">
                <div>

                    {chartLoading ? (
                        <div className="loading-indicator-2">Loading...</div>
                    ) : (
                        <div id="wrapper">
                            <CustomCharts chartState={chartState} setChartState={setChartState} />
                            {/* <div id="chart-line2">
                                <Chart options={chartState.options} series={chartState.series} type="line" height={430} />
                            </div>
                            <div id="chart-line">
                                <Chart options={chartState.optionsLine} series={chartState.seriesLine} type="area" height={130} />
                            </div> */}
                        </div>
                    )}
                    <div id="html-dist"></div>
                </div>
            </div>
            <div className="chart-time">
                {selectionRadios.map((radio, idx) => (
                    <h6 key={idx} className={`button-chart ${selectionChart === radio ? "active" : ""}`} onClick={(e) => updateChartData(radio)}>
                        {radio}
                    </h6>
                ))}
                <div style={{ display: "inline-block" }}>
                    <Dropdown
                        show={isDropdownOpen}
                        onToggle={(isOpen) => setIsDropdownOpen(isOpen)}>
                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />

                        <Dropdown.Menu>
                            <div className="date-range-dropdown-calendar">
                                Start Date
                                <div className="">
                                    <DatePicker
                                        className="dropdown-date-picker"
                                        dateFormat="dd/MM/yyyy"
                                        minDate={new Date('2015-06-22')}
                                        maxDate={chartEndDate}
                                        renderCustomHeader={({
                                            date,
                                            changeYear,
                                            changeMonth,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled,
                                        }) => (
                                            <div
                                                style={{
                                                    margin: 10,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                    className="select-calendar-arrow">
                                                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                                </button>
                                                <select
                                                    className="select-calendar"
                                                    value={date.getFullYear()}
                                                    onChange={({ target: { value } }) => changeYear(value)}
                                                >
                                                    {yearOptions.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>

                                                <select
                                                    className="select-calendar"
                                                    value={months[date.getMonth()]}
                                                    onChange={({ target: { value } }) =>
                                                        changeMonth(months.indexOf(value))
                                                    }
                                                >
                                                    {months.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>

                                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="select-calendar-arrow">
                                                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        )}
                                        selected={chartStartDate}
                                        onChange={(date) => setChartStartDate(date)}
                                    /></div>
                                End Date
                                <div>
                                    <DatePicker
                                        minDate={chartStartDate}
                                        maxDate={new Date()}
                                        className="dropdown-date-picker"
                                        dateFormat="dd/MM/yyyy"
                                        renderCustomHeader={({
                                            date,
                                            changeYear,
                                            changeMonth,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled,
                                        }) => (
                                            <div
                                                style={{
                                                    margin: 10,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                                                    className="select-calendar-arrow">
                                                    <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                                </button>
                                                <select
                                                    className="select-calendar"
                                                    value={date.getFullYear()}
                                                    onChange={({ target: { value } }) => changeYear(value)}
                                                >
                                                    {yearOptions.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>

                                                <select
                                                    className="select-calendar"
                                                    value={months[date.getMonth()]}
                                                    onChange={({ target: { value } }) =>
                                                        changeMonth(months.indexOf(value))
                                                    }
                                                >
                                                    {months.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>

                                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="select-calendar-arrow">
                                                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        )}
                                        selected={chartEndDate}
                                        onChange={(date) => setChartStartDate(date)}
                                    />
                                </div>
                            </div>
                            <div className="dropdown-table-button-div">
                                <Button className="dropdown-table-button" variant="secondary" onClick={() => handleChartDateRangeSearch()}>Search</Button>
                                <Button className="dropdown-table-button" variant="outline-secondary" onClick={() => handleCancelClick()}>Cancel</Button>
                            </div>
                        </Dropdown.Menu>

                    </Dropdown>
                </div>
                <div className="reference-container">
                    <div className="reference calender">NOAA Global Forecast System (GFS) was accessed from https://registry.opendata.aws/noaa-gfs-bdp-pds.</div>
                </div>
            </div>

        </div>)
};

export default AnalyticsView;