import React, { useState, useEffect, useMemo } from "react";
import { useParams } from 'react-router-dom';
import Chart from "react-apexcharts";
import GetHistoryData from '../components/GetHistoryDataByToken';
import { transformDataTable } from '../components/TransformedMiniData';
import 'react-datepicker/dist/react-datepicker.css';
import 'fontawesome-free/css/all.css';
import { signIn, fetchAuthSession } from 'aws-amplify/auth';
import ModelForHeatIndex from '../components/common/ModelForHeatIndex';
import { Image } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';
import '../App.css';
import 'leaflet/dist/leaflet.css';
import '../components/css/ChartPage.css';

const ChartPage = (props) => {

    const { token } = useParams();
    const miniChartOptions = useMemo(() => {
        var currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - 1);
        return {
            chart: {
                id: 'chart',
                type: 'line',
                zoom: {
                    autoScaleYaxis: true
                }
            },
            tooltip: {
                x: {
                    format: 'dd MMM yyyy'
                }
            },
            stroke: {
                width: 2
            },
            dataLabels: {
                enabled: false
            },
            fill: {
                opacity: 1,
            },
            markers: {
                size: 0,
            },
            annotations: {
                yaxis: [{
                    y: 0,
                    y2: 32.8,
                    borderColor: '#000',
                    fillColor: '#FFF103',
                    opacity: 0.2,
                    label: {
                        offsetY: 20,
                        borderColor: '#333',
                        style: {
                            fontSize: '10px',
                            color: '#333',
                            background: '#FFF103',
                        },
                        // text: 'Lower(Caution)',
                    }
                }, {
                    y: 32.8,
                    y2: 39.4,
                    borderColor: '#000',
                    fillColor: '#FEB019',
                    opacity: 0.2,
                    label: {
                        offsetY: 20,
                        borderColor: '#333',
                        style: {
                            fontSize: '10px',
                            color: '#333',
                            background: '#FEB019'
                        },
                        // text: 'Moderate',
                    }
                }, {
                    y: 39.4,
                    y2: 46.1,
                    borderColor: '#000',
                    fillColor: '#F3722C',
                    opacity: 0.2,
                    label: {
                        offsetY: 20,
                        borderColor: '#333',
                        style: {
                            fontSize: '10px',
                            color: '#333',
                            background: '#F3722C',
                        },
                        // text: 'High',
                    }
                }, {
                    y: 46.1,
                    y2: 100,
                    borderColor: '#000',
                    fillColor: '#D1232A',
                    opacity: 0.2,
                    label: {
                        offsetY: 20,
                        borderColor: '#333',
                        style: {
                            fontSize: '10px',
                            color: '#ffffff',
                            background: '#D1232A',
                        },
                        // text: 'Very hight to extreme',
                    }
                }
                ]
            },
            xaxis: {
                type: 'datetime',
            },
            yaxis: [
                {
                    seriesName: ["Heat Index"],
                    title: {
                        text: "Degree (°C)",
                    }, labels: {
                        formatter: (value) => Math.round(value)
                    }
                },
                {
                    seriesName: ["Rain Rate"],
                    title: {
                        text: "mm/day",
                    }, labels: {
                        formatter: (value) => Math.round(value)
                    }
                },
            ]
        }
    }, []);
    const [isAllowShowMap, setIsAllowShowMap] = useState(true);
    const [minDateTime, setMinDateTime] = useState(new Date());


    const [chartState, setChartState] = useState({
        miniChartSeries: [{
            data: []
        }],
        miniChartOptions: miniChartOptions
    });
    const [chartLoading, setChartLoading] = useState(true)
    const [modalShow, setModalShow] = useState(false);

    const selectionRadios = ["1M", "3M", "6M", "YTD", "1Y", "3Y", "4Y", "All"]
    const [selection, setSelection] = useState('1M');

    async function signInAsGuest() {
        try {
            await signIn({ username: 'guest_00001', password: 'r%3BGzbOO8Y~KI@%Ud#{' });

            let session = await fetchAuthSession();
            const authTokenTemp = session.tokens?.idToken?.toString();
            return { session, authTokenTemp };
        } catch (error) {
            console.error("Guest sign-in failed:", error);
            throw error;
        }
    }
    async function currentSession(parentUrl) {
        try {
            let session = await fetchAuthSession();
            let authTokenTemp = session.tokens?.idToken?.toString();

            // Check if the token is invalid or missing
            if (!authTokenTemp) {
                // Handle guest sign-in
                const guestSignInResult = await signInAsGuest();
                session = guestSignInResult.session;
                authTokenTemp = guestSignInResult.authTokenTemp;
            }




            let historyData = await GetHistoryData(token, parentUrl, authTokenTemp);
            if (historyData.length === 0) {
                setChartLoading(false);
                setIsAllowShowMap(false);
                return;
            }

            // Process history data
            const tabledata = await transformDataTable(historyData);

            // Update state with the processed data
            setMinDateTime(new Date(tabledata[0].data[0].x));
            setChartState({
                ...chartState,
                miniChartSeries: tabledata,
                miniChartOptions: miniChartOptions,
            });
            setChartLoading(false);
        } catch (error) {
            console.error('Error fetching JWT token:', error);
            // await handleLogout();
        }
    }

    useEffect(() => {


        const handleMessage = (event) => {
            if (event.data.type === 'parent_url_response') {
                let parentUrl = event.data.url;
                parentUrl = parentUrl.replace(/http[s]*\:\/\//, '');
                parentUrl = parentUrl.replace(/\/.*/, '');
                currentSession(parentUrl);
            }
        };

        // Send a message to request parent frame's URL
        window.parent.postMessage('get_parent_url', '*');

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };


    }, []);

    const updateData = (timeline) => {
        setSelection(timeline);
        let currentDate = new Date();
        switch (timeline) {
            case '1M':
                currentDate.setMonth(currentDate.getMonth() - 1);
                setChartState(prevState => ({ ...prevState, miniChartOptions: { ...prevState.miniChartOptions, xaxis: { ...prevState.miniChartOptions.xaxis, min: currentDate.getTime() } } }));
                break
            case '3M':
                currentDate.setMonth(currentDate.getMonth() - 3);
                setChartState(prevState => ({ ...prevState, miniChartOptions: { ...prevState.miniChartOptions, xaxis: { ...prevState.miniChartOptions.xaxis, min: currentDate.getTime() } } }));
                break
            case '6M':
                currentDate.setMonth(currentDate.getMonth() - 6);
                setChartState(prevState => ({ ...prevState, miniChartOptions: { ...prevState.miniChartOptions, xaxis: { ...prevState.miniChartOptions.xaxis, min: currentDate.getTime() } } }));
                break
            case '1Y':
                currentDate.setFullYear(currentDate.getFullYear() - 1);
                setChartState(prevState => ({ ...prevState, miniChartOptions: { ...prevState.miniChartOptions, xaxis: { ...prevState.miniChartOptions.xaxis, min: currentDate.getTime() } } }));
                break
            case '3Y':
                currentDate.setFullYear(currentDate.getFullYear() - 3);
                setChartState(prevState => ({ ...prevState, miniChartOptions: { ...prevState.miniChartOptions, xaxis: { ...prevState.miniChartOptions.xaxis, min: currentDate.getTime() } } }));
                break
            case '4Y':
                currentDate.setFullYear(currentDate.getFullYear() - 4);
                setChartState(prevState => ({ ...prevState, miniChartOptions: { ...prevState.miniChartOptions, xaxis: { ...prevState.miniChartOptions.xaxis, min: currentDate.getTime() } } }));
                break
            case 'YTD':
                currentDate.setMonth(0);
                currentDate.setDate(1);
                setChartState(prevState => ({ ...prevState, miniChartOptions: { ...prevState.miniChartOptions, xaxis: { ...prevState.miniChartOptions.xaxis, min: currentDate.getTime() } } }));
                break
            case 'All':
                currentDate = minDateTime;
                setChartState(prevState => ({ ...prevState, miniChartOptions: { ...prevState.miniChartOptions, xaxis: { ...prevState.miniChartOptions.xaxis, min: currentDate.getTime() } } }));
                break
            default:
                break;
        }
    }
    const mailtoLink = `mailto:${process.env.REACT_APP_MAIL_TO}?subject=${encodeURIComponent(process.env.REACT_APP_MAIL_REPORT_SUBJECT)}&body=${encodeURIComponent(process.env.REACT_APP_MAIL_REPORT_BODY)}`;

    return (
        <>
            {/* <div className="app mx-auto home"> */}
            {chartLoading ? (
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <Image src="/loading.jpg" alt="Potomac Logo" width={200} />
                    
                    <h1>Loading...</h1>
                    </div>
            ) : (
                isAllowShowMap ?
                    <div className="container-summary m-0 p-0">
                        <div className="right-col">
                            <div id="chart">
                                <div className="head-chart" style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className="toolbar">
                                        {selectionRadios.map((radio, idx) => (
                                            <h6 key={idx} className={`button-summary ${selection === radio ? "active" : ""}`} onClick={(e) => updateData(radio)}>
                                                {radio}
                                            </h6>
                                        ))}
                                    </div>
                                    <i className="fa fa-info-circle info-button" aria-hidden="true" onClick={() => setModalShow(true)} />
                                </div>
                                <div id="chart-timeline">
                                    <Chart options={chartState.miniChartOptions} series={chartState.miniChartSeries} type="line" />
                                </div>
                                <div className="reference">Power by <a href="https://potomac.porpinijandboon.com">Potomac</a>.</div>
                                {/* <div className="reference"><a href="https://potomac.porpinijandboon.com">https://potomac.porpinijandboon.com</a></div> */}
                            </div>
                        </div>
                    </div> :
                    <div style={{ textAlign: 'center', marginTop: '50px' }}>
                        <Image src="/notallow.jpg" alt="Potomac Logo" width={150} />
                        <h2>Not Allowed</h2>
                        <p>Please contact us at <a href={mailtoLink}>{process.env.REACT_APP_MAIL_TO}</a></p>
                        <p>Or Visit us at <a href="https://potomac.porpinijandboon.com">Potomac</a> for assistance.</p>
                    </div>)}
            {/* </div> */}
            <ModelForHeatIndex
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
};

export default ChartPage;
