import API from './api';

export default class Constants {
  static API = API;

  static typeOptions = [
    {
      value: 'heatidx',
      label: 'Heat Index',
      unit: 'Degree (°C)',
      color: '#72D4FF',
    },
    {
      value: 'temp',
      label: 'Surface Temperature',
      unit: 'Degree (°C)',
      color: '#AD6EFF',
    },
    {
      value: 'temp2m',
      label: 'Temperature',
      unit: 'Degree (°C)',
      color: '#FF80C4',
    },
    {
      value: 'relhum',
      label: 'Relative Humidity',
      unit: 'Percent (%)',
      color: '#FFBD74',
    },
    { value: 'precip', label: 'Rain Rate', unit: 'mm/day', color: '#64F1D9' },
    {
      value: 'accum_rain_rate',
      label: 'Annual Accumulated Rain Rate',
      unit: 'mm/year',
      color: '#002FEE',
    },
    { value: 'tpw', label: 'Precipitable Water', unit: 'mm', color: '#E5622A' },
    { value: 'pm25', label: 'PM 2.5', unit: 'µg/m³', color: '#C92D2D' },
  ];
}
