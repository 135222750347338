import Constants from '../constants';

const getSerieType = (serie) => {
  const parameterLabels = Constants.typeOptions.map((option) => option.label);
  if (parameterLabels.includes(serie.name)) {
    return 'variables';
  } else if (typeof serie.name === 'number') {
    return 'history';
  } else return 'location';
};

export default getSerieType;
