import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web
import articleSlice from "./features/article/articleSlice";
import dataLabelingSlice from "./features/dataLabeling/dataLabelingSlice";
import { combineReducers } from "redux";

// Persist configuration
const persistConfig = {
  key: "root",
  storage,
};

// Combine reducers
const rootReducer = combineReducers({
  article: articleSlice,
  dataLabeling: dataLabelingSlice,
});

// Persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Required to prevent warnings from redux-persist
    }),
});

// Persistor
const persistor = persistStore(store);

export { store, persistor };
