import axios from "axios";
import Constants from "../constants";

export default class HistoryDataServices {
  static getHistoryData = async (latitude, longitude, accessToken) => {
    try {
      const response = await axios.get(
        Constants.API.History.Data.GET(latitude, longitude),
        {
          headers: {
            Accept: "application/json",
            Authorization: accessToken,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };
}
