import axios from 'axios';

const fetchData = async (type, start_date,end_date,topic,accessToken) => {
  try {
    const response = await axios.get(
      `https://p1l4gt4ufg.execute-api.ap-southeast-1.amazonaws.com/v2/web/risk-assessment?type=${type}&start_date=${start_date}&end_date=${end_date}&topic=${topic}`,
      { headers:  {
        'Accept': 'application/json',
        'Authorization': accessToken,
      }}
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};

export default fetchData;
