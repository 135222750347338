import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Button, DropdownButton, ButtonGroup, Dropdown, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import LoadingModal from "../components/LoadingModal";
import { useDispatch, useSelector } from "react-redux";
import "../components/css/DataLabelingPage.css"; // Import a custom CSS file
import "../components/css/RiskAssessment.css"; // Import a custom CSS file
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import DatePicker from "react-datepicker";
import GetRiskAssessmentData from '../components/GetRiskAssessmentData';
import Select from 'react-select';

const RiskAssessment = () => {

  let historyData, predictData;
  const [topic, setTopic] = useState("world");
  const [selectType, setSelectType] = useState([{ value: 'heatidx', label: 'Heat Index', unit: 'Degree (°C)', color: "#72D4FF" }]);
  const [isTableDropdownOpen, setIsTableDropdownOpen] = useState(false);
  const [chartEndDate, setChartEndDate] = useState(new Date());
  const [isSubscribeUser, setIsSubscribeUser] = useState(true);
  const [selectionTable, setSelectionTable] = useState('3M');
  const [tableStartDate, setTableStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 3)));
  const [tableEndDate, setTableEndDate] = useState(new Date());
  const [locationList, setLocationList] = useState([]);

  const typeOptions = [
    { value: 'heatidx', label: 'Heat Index', unit: 'Degree (°C)', color: "#72D4FF" },
    { value: 'temp', label: 'Surface Temperature', unit: 'Degree (°C)', color: "#AD6EFF" },
    { value: 'temp2m', label: 'Temperature', unit: 'Degree (°C)', color: "#FF80C4" },
    { value: 'relhum', label: 'Relative Humidity', unit: 'Percent (%)', color: "#FFBD74" },
    { value: 'precip', label: 'Rain Rate', unit: 'mm/day', color: "#64F1D9" },
    { value: 'accum_rain_rate', label: 'Annual Accumulated Rain Rate', unit: 'mm/year', color: "#002FEE" },
    { value: 'tpw', label: 'Precipitable Water', unit: 'mm', color: "#E5622A" },
    { value: 'pm25', label: 'PM 2.5', unit: 'µg/m³', color: "#C92D2D" }
  ];

  const [tableData, setTableData] = useState({
    data: [], cols: [
      { field: 'location', header: 'Location' },
      { field: 'heatidx', header: 'Heat Index' }
    ]
  });
  const [allData, setAllData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const dt = useRef(null);
  const navigate = useNavigate();
  const yearOptions = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleTableDateRangeSearch = () => {
    setIsTableDropdownOpen(false);

    getData(selectType, tableStartDate.toISOString().split('T')[0], tableEndDate.toISOString().split('T')[0], topic);
  }
  const handleTableDateRangeCancel = () => {
    setIsTableDropdownOpen(false);
  };
  const checkContain = (data, list) => {
    return list.some(
      (item) =>
        item.value === data.value &&
        item.label === data.label &&
        item.unit === data.unit &&
        item.color === data.color
    );
  }

  const updateTableData = (timeline) => {
    setSelectionTable(timeline);
    let currentDate = new Date();
    setChartEndDate(new Date());
    setIsTableDropdownOpen(false);
    switch (timeline) {
      case '1M':
        currentDate.setMonth(currentDate.getMonth() - 1)
        break
      case '3M':
        currentDate.setMonth(currentDate.getMonth() - 3);
        break
      case '6M':
        currentDate.setMonth(currentDate.getMonth() - 6);
        break
      case '1Y':
        currentDate.setFullYear(currentDate.getFullYear() - 1)
        break
      case '3Y':
        currentDate.setFullYear(currentDate.getFullYear() - 3)
        break
      case '5Y':
        currentDate.setFullYear(currentDate.getFullYear() - 5)
        break
      case 'YTD':
        currentDate.setMonth(0);
        currentDate.setDate(1);
        break
      case 'All':
        currentDate = new Date('2020-01-01');
        break
      default:
        break;
    }
    setTableStartDate(currentDate);
    getData(selectType, currentDate.toISOString().split('T')[0], (new Date()).toISOString().split('T')[0], topic);
  }
  const selectionRadios = ["1M", "3M", "6M", "YTD", "1Y", "3Y", "5Y", "All"]

  const handleTypeChange = (selectedOption) => {
    let selectTypeTemp = selectType;
    if (checkContain(selectedOption, selectType)) {
      if (selectType.length === 1) {
        return;
      }
      selectTypeTemp = selectType.filter((item) => item.value !== selectedOption.value);
    } else {
      selectTypeTemp = [...selectType, selectedOption];
    }
    setSelectType(selectTypeTemp);
    getData(selectTypeTemp, tableStartDate.toISOString().split('T')[0], tableEndDate.toISOString().split('T')[0], topic);
  };
  const handleLocationChange = (location) => {
    let locationListTemp = locationList;
    if (locationList.includes(location)) {
      locationListTemp = locationList.filter((item) => item !== location);
    } else {
      locationListTemp = [...locationList, location];
    }
    setLocationList(locationListTemp);
    let data = allData;
    if (locationListTemp.length > 0) {
      data = allData.filter((item) => locationListTemp.includes(item.locationName));
      // data = allData.filter((item) => item.locationName in locationListTemp);
    }
    setTableData({
      data: data, cols: tableData.cols
    })
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { month: 'short', day: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  const exportColumns = tableData.cols.map((col) => ({ title: col.header, dataKey: col.field }));
  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const exportPdf = () => {
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, tableData.data);
        doc.save(`risk_assessment_${selectType.map((item) => item.label).join(', ')}_${topic}_at_${tableStartDate.toISOString().split('T')[0]}_${tableEndDate.toISOString().split('T')[0]}.pdf`);
      });
    });
  };

  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData.data);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });

      saveAsExcelFile(excelBuffer, `risk_assessment_${selectType.map((item) => item.label).join(', ')}_${topic}_at_${tableStartDate.toISOString().split('T')[0]}_${tableEndDate.toISOString().split('T')[0]}`);
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });

        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
    });
  };

  const loading = useSelector((state) => state.dataLabeling.loading);
  const dispatch = useDispatch();

  // const topicList = useMemo(() => ["parameter", "profit"], []);
  // const topicList = useMemo(() => ["parameter"], []);
  const topicList = useMemo(() => ["world","thailand"], []);


  const getData = (type, startDate, endDate, topic) => {
    let cols = [{ field: 'locationName', header: 'Location' }]
    type.forEach((item) => {
      cols.push({ field: item.value, header: item.label })
    })

    setTableData({
      data: [], cols: cols
    })

    GetRiskAssessmentData(type.map((item) => item.value).join(','), startDate, endDate, topic).then((data) => {
      setAllData(data);
      if (locationList.length > 0) {
        data = data.filter((item) => locationList.includes(item.locationName));
      }
      setTableData({
        data: data, cols: cols
      })
    });
  };

  useEffect(() => {
    setTopic(topicList[0]);
  }, [topicList]);

  useEffect(() => {
    getData(selectType, tableStartDate.toISOString().split('T')[0], tableEndDate.toISOString().split('T')[0], topic);
  }, [])
  const updateTopic = (item) => {
    setTopic(item)
    getData(selectType, tableStartDate.toISOString().split('T')[0], tableEndDate.toISOString().split('T')[0], item);

  };


  return (
    <>
      {loading ? (
        <LoadingModal />
      ) : (
        <div className="data-labeling-page">
          <div className="header-tab">
            {topicList.map((item) =>
              <div
                key={item}
                className={`title ${topic === item ? "active" : ""} text-capitalize`}
                onClick={() => updateTopic(item)}
              >
                {item.replace(/-/g, " ")}
              </div>
            )}
          </div>

          <div className="content-tab">

            <DropdownButton
              className="dropdown-type-history"
              variant="white"
              size="sm"
              as={ButtonGroup}
              title={`Type: ${selectType.map((item) => item.label).join(', ').length > 20 ? selectType.map((item) => item.label).join(', ').slice(0, 20) + '...' : selectType.map((item) => item.label).join(', ')}`}
              id="input-group-dropdown-3"
            >
              {typeOptions.map(option => (
                <Dropdown.Item key={option.value} href="#" onClick={() => handleTypeChange(option)}>
                  <Form.Check
                    type="checkbox"
                    label={option.label}
                    checked={checkContain(option, selectType)}
                    readOnly
                  />
                </Dropdown.Item>
              ))}
            </DropdownButton>

            {/* <Select
              className="basic-single flex-grow-1 select-country" // Use flex-grow-1 class to make Select fill remaining space
              classNamePrefix="select"
              // value={selectedCountryTemp}
              // onChange={handleCountryChange}
              // options={citylist}
              width={200}
              isSearchable
            /> */}
            <DropdownButton
              className="dropdown-type-history"
              variant="white"
              size="sm"
              as={ButtonGroup}
              title={`Location: ${locationList.length > 0 ? locationList.join(', ').length > 20 ? locationList.join(', ').slice(0, 20) + '...' : locationList.join(', ') : 'All'}`}
              id="input-group-dropdown-3"
            >
              <div className="px-3 py-2">
                <Form.Control
                  type="text"
                  placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className="custom-scrollable-menu">


                {(allData.filter(option =>option.locationName.toLowerCase().includes(searchTerm.toLowerCase()))).map(option => (
                  <Dropdown.Item key={option.locationName} href="#" onClick={() => handleLocationChange(option.locationName)}>
                    <Form.Check
                      key={option.locationName} 
                      type="checkbox"
                      label={option.locationName}
                      checked={locationList.includes(option.locationName)}
                      readOnly
                    />
                  </Dropdown.Item>
                ))}</div>
            </DropdownButton>
            <DropdownButton
              show={isTableDropdownOpen}
              onToggle={(isOpen) => setIsTableDropdownOpen(isOpen)}
              className="dropdown-type-history"
              variant="white"
              size="sm"
              as={ButtonGroup}
              title={`${formatDate(tableStartDate)} - ${formatDate(tableEndDate)}`}
              id="input-group-dropdown-3"
            >
              <div className="dropdown-type-table-div">
                {selectionRadios.map((radio, idx) => (
                  <h6 key={idx} className={`button-summary ${selectionTable === radio ? "active" : ""}`} onClick={(e) => updateTableData(radio)}>
                    {radio}
                  </h6>
                ))}
              </div>

              <div className="date-range-dropdown-calendar">
                Start Date
                <div className="table-date-range-dropdown">
                  <DatePicker
                    className="dropdown-date-picker"
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date('2015-06-22')}
                    maxDate={tableEndDate}
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    }) => (
                      <div
                        style={{
                          margin: 10,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                          className="select-calendar-arrow">
                          <i className="fa fa-chevron-left" aria-hidden="true"></i>
                        </button>
                        <select
                          className="select-calendar"
                          value={date.getFullYear()}
                          onChange={({ target: { value } }) => changeYear(value)}
                        >
                          {yearOptions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <select
                          className="select-calendar"
                          value={months[date.getMonth()]}
                          onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                          }
                        >
                          {months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="select-calendar-arrow">
                          <i className="fa fa-chevron-right" aria-hidden="true"></i>
                        </button>
                      </div>
                    )}
                    selected={tableStartDate}
                    onChange={(date) => setTableStartDate(date)}
                  /></div>
                End Date
                <div>
                  <DatePicker
                    className="dropdown-date-picker"
                    dateFormat="dd/MM/yyyy"
                    minDate={tableStartDate}
                    maxDate={new Date()}
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    }) => (
                      <div
                        style={{
                          margin: 10,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                          className="select-calendar-arrow">
                          <i className="fa fa-chevron-left" aria-hidden="true"></i>
                        </button>
                        <select
                          className="select-calendar"
                          value={date.getFullYear()}
                          onChange={({ target: { value } }) => changeYear(value)}
                        >
                          {yearOptions.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <select
                          className="select-calendar"
                          value={months[date.getMonth()]}
                          onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                          }
                        >
                          {months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="select-calendar-arrow">
                          <i className="fa fa-chevron-right" aria-hidden="true"></i>
                        </button>
                      </div>
                    )}
                    selected={tableEndDate}
                    onChange={(date) => setTableEndDate(date)}
                  />
                </div>
                <div className="dropdown-table-button-div">
                  <Button className="dropdown-table-button" variant="secondary" onClick={() => handleTableDateRangeSearch()}>Done</Button>
                  <Button className="dropdown-table-button" variant="outline-secondary" onClick={() => handleTableDateRangeCancel()}>Cancel</Button>
                </div>
              </div>
            </DropdownButton>
            <div className="d-flex align-items-center gap-2 justify-content-end">
              {isSubscribeUser ?
                <div className="d-flex gap-2">
                  {/* <ButtonPrime className="rounded-button" type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
<ButtonPrime className="rounded-button" type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
<ButtonPrime className="rounded-button" type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" /> */}
                  <div className="download-tab" onClick={() => exportCSV(false)}><i className="fa fa-download" aria-hidden="true" style={{ marginRight: "10px" }} /> CSV</div>
                  <div className="download-tab" onClick={exportExcel}><i className="fa fa-download" aria-hidden="true" style={{ marginRight: "10px" }} /> EXCEL</div>
                  <div className="download-tab" onClick={exportPdf}><i className="fa fa-download" aria-hidden="true" style={{ marginRight: "10px" }} /> PDF</div>
                </div>
                :
                <>
                  <div className="d-flex gap-2" onClick={() => { navigate('/signup'); }}>
                    {/* <div className="download-lock-tab" > <img src="/padlock.png" height={20} alt="Lock Icon" /></div> */}
                    <div className="download-lock-tab" style={{ filter: "blur(2px)" }} ><i className="fa fa-download" aria-hidden="true" style={{ marginRight: "10px" }} /> CSV</div>
                    <div className="download-lock-tab" style={{ filter: "blur(2px)" }} ><i className="fa fa-download" aria-hidden="true" style={{ marginRight: "10px" }} /> EXCEL</div>
                    <div className="download-lock-tab" style={{ filter: "blur(2px)" }}><i className="fa fa-download" aria-hidden="true" style={{ marginRight: "10px" }} /> PDF</div>
                  </div>
                </>
              }

            </div>
            <div className="card">
              <Tooltip target=".export-buttons>button" position="bottom" />


              <DataTable ref={dt} value={tableData.data} selectionMode="single" paginator rows={10} >
                {tableData.cols.map((col, index) => (
                  <Column key={index} field={col.field} header={col.header} sortable />
                ))}
              </DataTable>
            </div>

            <div className="reference-table">NOAA Global Forecast System (GFS) was accessed from https://registry.opendata.aws/noaa-gfs-bdp-pds.</div>
          </div>
        </div>
      )}
    </>
  );
};

export default RiskAssessment;
