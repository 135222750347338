import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@aws-amplify/ui-react/styles.css";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setEnableArticle } from "./states/features/article/articleSlice";
import { setEnableDataLabeling } from "./states/features/dataLabeling/dataLabelingSlice";
import RoutesPage from "./Routes";

Amplify.configure(awsExports);

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // const searchParamsArticle = new URLSearchParams(window.location.search);
    // const article = searchParamsArticle.get("article");
    // if (article === "true") dispatch(setEnableArticle(true));
    dispatch(setEnableArticle(true));

    const searchParamsDataLabeling = new URLSearchParams(
      window.location.search
    );
    const dataLabeling = searchParamsDataLabeling.get("data-labeling");
    if (dataLabeling === "true") dispatch(setEnableDataLabeling(true));
  }, [dispatch]);

  return <RoutesPage />;
};

export default App;
