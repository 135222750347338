import axios from 'axios';

const fetchData = async (latitude, longitude,accessToken) => {
  try {
    const response = await axios.get(
      `https://p1l4gt4ufg.execute-api.ap-southeast-1.amazonaws.com/v2/web/location/predict?latitude=${latitude}&longitude=${longitude}`,
      { headers:  {
        'Accept': 'application/json',
        'Authorization': accessToken,
      }}
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};

export default fetchData;
