import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Services from "../services";
import ArticleOutline from "../components/ArticleOutline";
import LoadingModal from "../components/LoadingModal";
import "../components/css/ArticlePage.css";
import MarkdownRenderer from "../components/MarkdownRenderer";
import { useSelector } from "react-redux";
import { marked } from "marked";

const ArticlePage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const progress = useSelector((state) => state.article.progress);

  const [isLoading, setIsLoading] = useState(true);
  const [ArticleName, setArticleName] = useState("");
  const [article, setArticle] = useState("");
  const [imageMap, setImageMap] = useState({});
  const [topics, setTopics] = useState([]);
  const [isArticleOutlineOpen, setIsArticleOutlineOpen] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const articleContentRegEx = new RegExp("[0-9]+/[0-9]+.md");
    const articleNameRegEx = new RegExp("[0-9]+/metadata.md");
    const imageRegEx = new RegExp("(png|jpg|jpeg|gif|bmp|webp|tff|svg)$");
    Services.Article.get(params.id).then((res) => {
      const newImageMap = {};
      Object.keys(res).forEach((key) => {
        const fileExtension = key.split(".").pop();
        if (articleContentRegEx.test(key)) {
          setArticle(res[key]);
        } else if (articleNameRegEx.test(key)) {
          setArticleName(res[key].split("#")[1]);
        } else if (imageRegEx.test(fileExtension)) {
          const imageKey = key.split("/").pop();
          newImageMap[imageKey] = res[key];
        }
      });
      setImageMap(newImageMap);
      setIsLoading(false);
    }).catch((error) => {
      console.error(error);
      setIsLoading(false);
    });
  }, [params.id]);

  // Extract H2 headers from the markdown
  useEffect(() => {
    // Convert Markdown to HTML
    const htmlContent = marked(article);

    // Parse the HTML to extract headers
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    const headers = Array.from(tempDiv.querySelectorAll("h1, h2")).map((header) => {
      if (header.tagName === "H1") {
        return {
          id: header.innerText.toLowerCase().replace(/\s+/g, "-"),
          text: "Introduction",
        };
      } else {
        return {
          id: header.innerText.toLowerCase().replace(/\s+/g, "-"),
          text: header.innerText,
        };
      }
    });
    setTopics(headers);
  }, [article]);

  // Scroll to a topic
  const scrollToTopic = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const query = new URLSearchParams(window.location.search);
      const openCourseOutline = query.get("outline");
      if (openCourseOutline) {
        setIsArticleOutlineOpen(true);
      }
    }, 500);
  }, []);

  useEffect(() => {
    navigate(
      `/article/${params.id}`,
      {
        replace: true,
      }
    );
  }, [navigate, params.id, progress]);

  return (
    <div className="article-container">
      <div>
        <div className="header">
          <div className="menu-list" style={{ flex: 1 }}>
            <button className="menu" onClick={() => navigate("/?tab=article")}>
              <i className="pi pi-home menu-icon"></i>
            </button>
          </div>
          <div className="navigation-button-container" style={{ flex: 1 }}>
            <button onClick={() => setIsArticleOutlineOpen(true)}>
              Article Outline
            </button>
          </div>
          <div style={{ flex: 1 }}></div>
        </div>
        {isLoading && <LoadingModal />}
        {!isLoading && isArticleOutlineOpen && (
          <ArticleOutline
            title={ArticleName}
            topics={topics}
            onSelect={scrollToTopic}
            onClose={() => setIsArticleOutlineOpen(false)}
          />
        )}
        <div className="content-container">
          <div className={article ? "article-content" : "no-article"}>
            <MarkdownRenderer
              markdown={article}
              imageMap={imageMap}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticlePage;
